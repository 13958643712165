<template>
  <div>
    <b-card
      class="mt-10"
      :title="$t('Explanation')"
    >
      <vs-row>

        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="id-card-alt"
            color="light-primary"
            icon-color-bg="primary"
            icon-color="null"
          >
            {{ $t('Transaction ID') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="table"
            color="light-primary"
            icon-color-bg="primary"
            icon-color="null"
          >
            {{ $t('Issue Date') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="arrow-down"
            color="light-success"
            icon-color-bg="success"
            icon-color="null"
          >
            {{ $t('In coming') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="arrow-up"
            color="light-danger"
            icon-color-bg="danger"
            icon-color="null"
          >
            {{ $t('Out Coming') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="exchange-alt"
            color="light-warning"
            icon-color-bg="warning"
            icon-color="null"
          >
            {{ $t('Balance') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="layer-group"
            color="light-success"
            icon-color-bg="success"
            icon-color="null"
          >
            {{ $t('Money reciever') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="2"
          vs-sm="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="layer-group"
            color="light-danger"
            icon-color-bg="danger"
            icon-color="null"
          >
            {{ $t('Money sender') }}
          </vx-badge>
        </vs-col>
      </vs-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "TransactionExplanations",
}

</script>

<style scoped>

</style>
