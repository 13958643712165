<template>
  <div>
    <div
      id="data-list-list-view"
      class="data-list-container"
    >
      <vs-row>
        <vs-col
          vs-w="2"
          vs-sm="12"
          class="ml-lg-2"
        ><b-card
          class="mb-3"
          justify="center"
          align="center"
        >
          <b-avatar
            class="mb-1"
            size="lg"
            variant="light-primary"
          >
            <fa-icon
              size="2"
              icon="layer-group"
              pack="fas"
            />
          </b-avatar>

          <h2 class="mb-1 font-weight-bolder">
            {{ cashier.name }}
          </h2>

          <span>{{ $t('Cashier Name') }}</span>

        </b-card>
        </vs-col>
        <vs-col
          vs-w="2"
          vs-sm="12"
          class="ml-lg-2"
        >
          <b-card
            class="mb-3"
            justify="center"
            align="center"
          >
            <b-avatar
              class="mb-1"
              size="lg"
              variant="light-success"
            >
              <fa-icon
                size="2"
                icon="arrow-down"
                pack="fas"
              />
            </b-avatar>

            <h2 class="mb-1 font-weight-bolder">
              <vx-badge
                color="light-success"
                icon-color-bg="success"
                icon-color="null"
              >
                {{ cashier.income }}
              </vx-badge>
            </h2>

            <span>{{ $t('Total Income') }}</span>
          </b-card>
        </vs-col>
        <vs-col
          vs-w="2"
          vs-sm="12"
          class="ml-lg-2"
        >
          <b-card
            class="mb-3"
            justify="center"
            align="center"
          >
            <b-avatar
              class="mb-1"
              size="lg"
              variant="light-danger"
            >
              <fa-icon
                size="2"
                icon="arrow-up"
                pack="fas"
              />
            </b-avatar>

            <h2 class="mb-1 font-weight-bolder">
              <vx-badge
                color="light-danger"
                icon-color-bg="danger"
                icon-color="null"
              >
                {{ cashier.outcome }}
              </vx-badge>
            </h2>

            <span>{{ $t('Total Outcome') }}</span>
          </b-card>
        </vs-col>
        <vs-col
          vs-w="2"
          vs-sm="12"
          class="ml-lg-2"
        >
          <b-card
            class="mb-3"
            justify="center"
            align="center"
          >
            <b-avatar
              class="mb-1"
              size="lg"
              variant="light-warning"
            >
              <fa-icon
                size="2"
                icon="exchange-alt"
                pack="fas"
              />
            </b-avatar>

            <h2 class="mb-1 font-weight-bolder">
              <vx-badge
                color="light-warning"
                icon-color-bg="warning"
                icon-color="null"
              >
                {{ cashier.balance }}
              </vx-badge>
            </h2>

            <span>{{ $t('Balance') }}</span>
          </b-card>
        </vs-col>
        <vs-col
          vs-w="2"
          vs-sm="12"
          class="ml-lg-2"
        >
          <b-card
            class="mb-3"
            justify="center"
            align="center"
          >
            <b-avatar
              class="mb-1"
              size="lg"
              variant="light-primary"
            >
              <fa-icon
                size="2"
                icon="calendar-plus"
                pack="fas"
              />
            </b-avatar>

            <h2 class="mb-1 font-weight-bolder">
              <vx-badge
                color="light-primary"
                icon-color-bg="primary"
                icon-color="null"
              >
                {{ cashier.last_transaction_from }}
              </vx-badge>
            </h2>

            <span>{{ $t('Last Transaction Date') }}</span>
          </b-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "SingleCashier",
  props:['cashier']
}

</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
