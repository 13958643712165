<template>
  <div>
    <b-modal
      v-model="localValue"
      cancel-variant="gradient-danger"
      :title="$t('Create Transaction')"
      hide-footer
      @hidden="close"
    >
      <vs-row class="mb-2">
        <vs-col
          vs-w="12"
        >
          <vs-select
            v-model="transactionData.transaction_to"
            :placeholder="$t('Select User')"
            autocomplete
            style="width: 100%"
            class="selectExample mt-1 w-full"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <vs-select-item
              v-for="(item,index) in users"
              :key="index"
              :value="index"
              :text="$t(item)"
            />
          </vs-select>
          <vs-alert
            v-show="errors.transaction_to"
            v-if="errors.transaction_to"
            color="danger"
            active="true"
          >
            {{ $t(errors.transaction_to[0]) }}
          </vs-alert>
        </vs-col>
        <vs-col
          vs-w="12"
        >
          <vs-select
            v-model="transactionData.transaction_category_id"
            autocomplete
            :placeholder="$t('Select Category')"
            class="selectExample mt-1 pt-1 w-full"
            style="width: 100%;"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <vs-select-item
              v-for="(item,index) in transactionCategories"
              :key="index"
              :value="item.id"
              :text="item.name"
            />
          </vs-select>
          <vs-alert
            v-show="errors.transaction_category_id"
            v-if="errors.transaction_category_id"
            color="danger"
            active="true"
          >
            {{ $t(errors.transaction_category_id[0]) }}
          </vs-alert>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col
          vs-w="6"
          vs-sm="12"
          class="mb-2 pr-2"
        >
          <span> {{ $t('Date') }}</span>
          <flat-pickr
            v-model="transactionData.issue_date"
            class="form-control"
          />
          <vs-alert
            v-show="errors.issue_date"
            v-if="errors.issue_date"
            color="danger"
            active="true"
          >
            {{ $t(errors.issue_date[0]) }}
          </vs-alert>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-sm="12"
        >
          <br>
          <vx-input
            v-model="transactionData.amount"
            type="number"
            :label="$t('Amount')"
          />
          <vs-alert
            v-show="errors.amount"
            v-if="errors.amount"
            color="danger"
            active="true"
          >
            {{ $t(errors.amount[0]) }}
          </vs-alert>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col
          class="mb-1 px-1"
          vs-w="6"
          vs-s="6"
          vs-xs="12"
        >
          <vs-checkbox v-model="transactionData.is_deserved">
            {{ $t('isDeserved') }}
          </vs-checkbox>
          <vs-alert
            v-show="errors.is_deserved"
            v-if="errors.is_deserved"
            color="danger"
            active="true"
          >
            {{ $t(errors.is_deserved[0]) }}
          </vs-alert>
        </vs-col>
      </vs-row>
      <vs-row class="mb-2">
        <vs-col
          class="px-1"
          vs-w="12"
        >
          <b-form-textarea

            id="textarea-default"
            v-model="transactionData.description"
            :placeholder="$t('description')"
            rows="3"
          />
          <vs-alert
            v-show="errors.description"
            v-if="errors.description"
            color="danger"
            active="true"
          >
            {{ $t(errors.description[0]) }}
          </vs-alert>
        </vs-col>
        <vs-col class="mt-1">
          <hr>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-align="flex-end"
          vs-justify="flex-end"
        >
          <b-button
            variant="gradient-success"
            @click="makeTransaction"
          >
            {{ $t('Create Transaction') }}
          </b-button>
        </vs-col>
      </vs-row>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'NewTransaction',
  components: {
    flatPickr,
  },
  props: ['value', 'cashierId'],
  data() {
    return {
      transactionData: {
        transaction_to: '',
        transaction_category_id: '',
        issue_date: '',
        amount: '',
        is_deserved: false,
        description: '',
      },
      errors: [],
    }
  },
  computed: {
    users() {
      return this.$store.getters['user/usersNames']
    },
    transactionCategories() {
      return this.$store.getters['transaction/transactionCategories']
    },
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    this.getData()
    // this.$store.dispatch("transactionCategory/GetData")
    // this.$store.dispatch("account/GetData")
  },
  methods: {
    getData() {
      this.$store.dispatch('user/getUsersNames')
      this.$store.dispatch('transaction/getTransactionCategories')
    },
    makeTransaction() {
      this.$store.dispatch('transaction/storeTransaction', { id: this.cashierId, data: this.transactionData }).then(() => {
        if (this.$route.params.cashierId) {
          this.$store.dispatch('transaction/getCashierTransactions', { id: this.$route.params.cashierId })
          this.$store.dispatch('cashier/getCashier', this.$route.params.cashierId)
        } else this.$store.dispatch('cashier/GetData')
        this.close()
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
    close() {
      this.localValue = false
      this.transactionData.transaction_to = ''
      this.transactionData.transaction_category_id = ''
      this.transactionData.issue_date = ''
      this.transactionData.amount = ''
      this.transactionData.description = ''
      this.transactionData.is_deserved = false
    },
  },
}
</script>
<style lang="scss">
@import './src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
