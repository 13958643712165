<template>
  <div>
    <new-transaction
      v-model="newTransactionStatus"
      :cashier-id="$route.params.cashierId"
    />
    <single-cashier :cashier="Cashier" />
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <b-card>
          <div slot="header">
            <h3>
              {{ $t('Transactions Details') }}
            </h3>
          </div>

          <vs-row vs-justify="center">
            <vs-col class="mt-6 pt-6">
              <vs-row>
                <vs-col
                  v-if="false"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="3"
                  vs-sm="6"
                >
                  <vs-button
                    color="warning"
                    type="gradient"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      :icon="['fas', 'print']"
                    />
                    {{ $t('Export Selected') }}
                  </vs-button>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="3"
                  vs-sm="6"
                >
                  <vs-button
                    color="success"
                    type="gradient"
                    @click="newTransactionStatus=true"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      :icon="['fas', 'exchange-alt']"
                    />
                    {{ $t('Transfer Money') }}
                  </vs-button>
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
          <div>
            <vs-table
              stripe
              :data="transactions"
            >
              <template slot="header">
                <h3 class="mb-5 mt-5">
                  {{ $t('Details') }}
                </h3>
              </template>
              <template slot="thead">
                <vs-th>{{ $t('No') }}</vs-th>
                <vs-th>{{ $t('Issue Date') }}</vs-th>
                <vs-th>{{ $t('Category') }}</vs-th>
                <vs-th>{{ $t('Description') }}</vs-th>
                <vs-th>{{ $t('Income') }}</vs-th>
                <vs-th>{{ $t('Outcome') }}</vs-th>
                <vs-th>{{ $t('Balance') }}</vs-th>
                <vs-th>{{ $t('Beneficiary') }}</vs-th>
                <vs-th>{{ $t('Affected') }}</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr
                  v-for="(tr, indextr) in data"
                  :key="indextr"
                >
                  <vs-td :data="tr.no">
                    <vx-badge
                      color="light-primary"
                      icon-color-bg="primary"
                      icon-color="null"
                    >
                      {{ tr.no }}
                    </vx-badge>
                  </vs-td>
                  <vs-td :data="tr.issueDate">
                    <vx-badge
                      pack="fas"
                      icon="table"
                      color="light-primary"
                      icon-color-bg="primary"
                      icon-color="null"
                    >
                      {{ tr.issue_date }}
                    </vx-badge>
                  </vs-td>
                  <vs-td :data="tr.category">
                    {{ tr.category }}
                  </vs-td>
                  <vs-td :data="tr.description">
                    {{ tr.description }}
                  </vs-td>
                  <vs-td :data="tr.income">
                    <font-awesome-icon
                      v-if="tr.income"
                      color="rgb(var(--vs-success))"
                      :icon="['fas', 'arrow-down']"
                    />
                    {{ tr.income ? tr.income : '-' }}
                  </vs-td>
                  <vs-td :data="tr.outcome">
                    <font-awesome-icon
                      v-if="tr.outcome"
                      color="rgb(var(--vs-danger))"
                      :icon="['fas', 'arrow-up']"
                    />
                    {{ tr.outcome ? tr.outcome : '-' }}
                  </vs-td>
                  <vs-td :data="tr.balance">
                    <font-awesome-icon
                      v-if="tr.balance"
                      color="rgb(var(--vs-warning))"
                      :icon="['fas', 'exchange-alt']"
                    />
                    {{ tr.balance }}
                  </vs-td>
                  <vs-td :data="tr.beneficiary">
                    <a
                      v-if="tr.beneficiary"
                      :href="$router.resolve({name: 'AccountTransactions',params: { accountId: tr.beneficiary_id }}).href"
                    >
                      <vx-badge
                        pack="fas"
                        :icon="tr.beneficiary_type==='cashier'?'layer-group':'user'"
                        color="light-success"
                        icon-color-bg="success"
                        icon-color="null"
                      >
                        {{ tr.beneficiary }}
                      </vx-badge>
                    </a>
                  </vs-td>
                  <vs-td :data="tr.affected">
                    <a
                      v-if="tr.affected"
                      :href="$router.resolve({name: 'AccountTransactions',params: { accountId: tr.affected_id }}).href"
                    >
                      <vx-badge
                        pack="fas"
                        :icon="tr.affected_type==='cashier'?'layer-group':'user'"
                        color="light-danger"
                        icon-color-bg="danger"
                        icon-color="null"
                      >
                        {{ tr.affected }}
                      </vx-badge>
                    </a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>

        </b-card>
      </vs-col>
    </vs-row>
    <transaction-explanations />
  </div>
</template>

<script>

import SingleCashier from '@/views/dashboard/cashier/components/SingleCashierDetails.vue'
import TransactionExplanations from '@/views/dashboard/cashier/components/transactionExplanation.vue'
import NewTransaction from './NewTransaction.vue'

export default {
  components: { TransactionExplanations, SingleCashier, NewTransaction },
  data() {
    return {
      newTransactionStatus: false,
    }
  },
  computed: {
    transactions() {
      return this.$store.getters['transaction/cashierTransactions']
    },
    Cashier() {
      return this.$store.getters['cashier/cashier']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('transaction/getCashierTransactions', { id: this.$route.params.cashierId })
      this.$store.dispatch('cashier/getCashier', this.$route.params.cashierId)
    },
  },
}
</script>
